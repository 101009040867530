export const _hasPermission = (mustHavePermission, permissions) => {
    const result = permissions.includes(mustHavePermission)
    return result
}

export const _hasOnePermissionIn = (mustHaveOnePermission, permissions) => {
    const result = permissions.some(permission => mustHaveOnePermission.includes(permission))
    return result
}

export const _hasManyPermissions = (mustHavePermissions, permissions) => {
    const result = permissions.every(permission => {
        return mustHavePermissions.indexOf(permission) !== -1;
    })
    return result
}