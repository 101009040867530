import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { RiWifiOffLine } from 'react-icons/ri'

export const OfflinePage = () => {
    return (
        <div className='fixed inset-0 z-50 h-screen w-screen flex items-center justify-center bg-mkps-primary-light/90'>
            <div className='flex flex-col items-center justify-center gap-3'>
                <RiWifiOffLine className='text-white h-20 w-20' />
                <div className='flex items-center gap-3 text-white'>
                    Tidak ada koneksi internet
                </div>
                <Button onClick={() => window.location.reload()}>Muat Ulang</Button>
            </div>
        </div>
    )
}
