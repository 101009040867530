const NavbarConfig = [
  // {
  //   name: 'Konten',
  //   permission: null,
  //   route: '/konten',
  //   href: '/konten/beranda',
  // },
  {
    name: 'Penjualan',
    permission: null,
    route: '/penjualan',
    href: '/penjualan/dashboard',
  },
  {
    name: 'Laporan',
    permission: null,
    route: '/laporan',
    href: '/laporan/penjualan/transaksi',
  },
  {
    name: 'Pengaturan',
    permission: null,
    route: '/pengaturan',
    href: '/pengaturan/outlet',
  },
]

export default NavbarConfig
