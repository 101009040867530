import React, { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
export const LoadingPage = () => {
    return (
        <div className='h-screen w-screen flex items-center justify-center bg-mkps-primary-light'>
            <div className='flex flex-col items-center justify-center gap-3'>
                <img
                    className="mx-auto h-12 w-auto"
                    src="/logo.png"
                    alt="Workflow"
                />
                <div className='flex items-center gap-3 text-white'>
                    <AiOutlineLoading3Quarters className='animate-spin' />
                    Loading...
                </div>
            </div>
        </div>
    )
}
