import { atom } from "recoil";

export const outletState = atom({
    key: 'outletState',
    default: {
        id: '',
        name: 'Semua Outlet',
    }
})

export const listOutletState = atom({
    key: 'listOutletState',
    default: [
        {
            id: '',
            name: 'Semua Outlet',
            isActive: true
        }
    ]
})