import { BiCoffeeTogo } from "react-icons/bi"
import { HiQrcode } from "react-icons/hi"

const SidebarSalesConfig = [
  // Owner Penjualan
  {
    name: 'Dashboard',
    permission: null,
    route: '/penjualan/dashboard',
    childs: [],
    icon: (
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
        />
      </svg>
    ),
  },
  {
    name: 'Produk',
    permission: 'READ_PRODUCT',
    route: '/penjualan/produk',
    icon: (
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
        />
      </svg>
    ),
    childs: [
      {
        name: 'Departemen',
        permission: null,
        route: '/penjualan/produk/departemen',
      },
      {
        name: 'Kategori',
        permission: null,
        route: '/penjualan/produk/kategori',
      },
      {
        name: 'Daftar Produk',
        permission: null,
        route: '/penjualan/produk/daftar-produk',
      },
      {
        name: 'Paket Produk',
        permission: null,
        route: '/penjualan/produk/paket',
      },
    ],
  },
  {
    name: 'Inventori',
    permission: null,
    route: '/penjualan/inventori',
    icon: (
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
        />
      </svg>
    ),
    childs: [
      {
        name: 'Stok',
        permission: null,
        route: '/penjualan/inventori/stok',
      },
      {
        name: 'Bahan Baku',
        permission: null,
        route: '/penjualan/inventori/bahan-baku',
      },
      {
        name: 'Aset Outlet',
        permission: null,
        route: '/penjualan/inventori/aset-outlet',
      },
      {
        name: 'Supplier',
        permission: null,
        route: '/penjualan/inventori/supplier',
      },
      {
        name: 'Mutasi Stok',
        permission: null,
        route: '/penjualan/inventori/mutasi-stok',
      },
    ],
  },
  {
    name: 'Promosi',
    permission: null,
    route: '/penjualan/promosi/list',
    icon: (
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
        />
      </svg>
    ),
    childs: [],
  },
  {
    name: 'Komisi',
    permission: null,
    route: '/penjualan/komisi',
    icon: (
      <BiCoffeeTogo className="h-6 w-6" />
    ),
    childs: [],
  },
  {
    name: 'Pelanggan',
    permission: null,
    route: '/penjualan/pelanggan',
    icon: (
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        ></path>
      </svg>
    ),
    childs: [],
  },
  {
    name: 'QR Code',
    permission: null,
    route: '/penjualan/qr-code',
    icon: (
      <HiQrcode className="w-5 h-5" />
    ),
    childs: [],
  },
  // {
  //   name: 'Supplier',
  //   permission: null,
  //   route: '/penjualan/inventori/supplier',
  //   icon: (
  //     <TbPackgeImport
  //       className="w-6 h-6"
  //     />
  //   ),
  //   childs: [],
  // },

  // sales
  // {
  //   name: 'Beranda',
  //   permission: null,
  //   route: '/beranda',
  //   childs: [],
  //   icon: (
  //     <svg
  //       className="w-6 h-6"
  //       fill="none"
  //       stroke="currentColor"
  //       viewBox="0 0 24 24"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Transaksi',
  //   permission: null,
  //   route: '/transaksi',
  //   childs: [],
  //   icon: (
  //     <svg
  //       className="w-6 h-6"
  //       fill="none"
  //       stroke="currentColor"
  //       viewBox="0 0 24 24"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Pengguna',
  //   permission: null,
  //   route: '/pengguna',
  //   childs: [],
  //   icon: (
  //     <svg
  //       className="w-6 h-6"
  //       fill="none"
  //       stroke="currentColor"
  //       viewBox="0 0 24 24"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
  //       />
  //     </svg>
  //   ),
  // },
  // {
  //   name: 'Voucher',
  //   permission: null,
  //   route: '/voucher',
  //   childs: [],
  //   icon: (
  //     <svg
  //       className="w-6 h-6"
  //       fill="none"
  //       stroke="currentColor"
  //       viewBox="0 0 24 24"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
  //       />
  //     </svg>
  //   ),
  // },
];

export default SidebarSalesConfig;