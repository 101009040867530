import {useEffect, useRef, useState} from 'react'
import {useRouter} from 'next/router'
import {Transition} from '@headlessui/react'
import Link from 'next/link'
import Image from 'next/image'
import SidebarItem from './SidebarItem'
import SidebarItemWithChild from './SidebarItemWithChild'
// import useOnClickOutside from '../../hooks/useOnClickOutside'

export default function SidebarOverlay(
    {
        openSidebar,
        setOpenSidebar,
        data,
        navbarData,
    }
) {
    const {asPath} = useRouter()
    // const btnRef = useRef()
    // useOnClickOutside(btnRef, () => setOpenProfile(false))

    return (
        <div className={openSidebar ? 'block' : 'hidden'}>
            <div className="fixed inset-0 flex z-40">
                <Transition
                    show={openSidebar}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
                    </div>
                </Transition>
                <Transition
                    show={openSidebar}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                    className="contents"
                >
                    <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-mkps-primary">
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                            <button
                                className="ml-1 flex items-center justify-center h-10 w-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                onClick={() => setOpenSidebar(false)}
                            >
                                <span className="sr-only">Close sidebar</span>
                                {/* <!-- Heroicon name: outline/x --> */}
                                <svg
                                    className="h-6 w-6 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="flex-shrink-0 flex items-center justify-center px-4">
                            <Image src="/logo.png" alt="logo" width={50} height={50}/>
                            <div className="font-semibold ml-2 text-white">DASHBOARD</div>
                        </div>
                        <div className="mt-5 flex-1 h-0 overflow-y-auto">
                            <nav className="px-2 space-y-1">
                                {navbarData?.map((item, idx) => (
                                    <a
                                        key={idx}
                                        className="text-gray-200 hover:bg-mkps-primary-light hover:text-gray-100 group flex items-center px-2 py-2 text-sm font-medium cursor-pointer"
                                    >
                                        {item?.name}
                                    </a>
                                ))}
                            </nav>
                            <div className="my-4 px-3">
                                <hr/>
                            </div>
                            <nav className="px-2 space-y-1">
                                {data?.map((item, idx) => (
                                    <div key={idx}>
                                        {item?.childs?.length === 0 ? (
                                            <SidebarItem data={item}/>
                                        ) : (
                                            <SidebarItemWithChild data={item}/>
                                        )}
                                    </div>
                                ))}
                            </nav>
                        </div>
                    </div>
                </Transition>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
                </div>
            </div>
        </div>
    )
}
