import { useRecoilValue } from 'recoil'
import ActiveLink from '../../components/ActiveLink'
import { userState } from '../../store/userState'

export default function NavbarItem({ data, role, permissions }) {
  const user  = useRecoilValue(userState)
  const routeLink = (data.href === '/pengaturan/outlet' && user?.store !== null) ? `${data.href}/${user?.store?.id}` : data.href

  return (
    <ActiveLink href={routeLink} activeClassName='navbar-active'>
      <a className={'navbar-item'}>
        {data.name}
      </a>
    </ActiveLink>
  )
}
