const SidebarReportConfig = [
    // Owner Laporan
    {
        name: 'Penjualan',
        permission: null,
        route: '/laporan/penjualan',
        childs: [
            {
                name: 'Laporan Transaksi',
                permission: null,
                route: '/laporan/penjualan/transaksi',
            },
            {
                name: 'Laporan Produk',
                permission: null,
                route: '/laporan/penjualan/produk',
            },
            {
                name: 'Laporan Harian',
                permission: null,
                route: '/laporan/penjualan/harian',
            },
            {
                name: 'Laporan Outlet',
                permission: null,
                route: '/laporan/penjualan/outlet',
            },
            {
                name: 'Laporan Promosi',
                permission: null,
                route: '/laporan/penjualan/promosi',
            },
            {
                name: 'Laporan Tutup Kasir',
                permission: null,
                route: '/laporan/penjualan/tutup-kasir',
            },
        ],
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                />
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                />
            </svg>
        ),
    },
    {
        name: 'Keuangan',
        permission: null,
        route: '/laporan/keuangan',
        childs: [
            {
                name: 'Buku Kas & Bank',
                permission: null,
                route: '/laporan/keuangan/kas-dan-bank',
            },
            {
                name: 'Daftar Pemasukan',
                permission: null,
                route: '/laporan/keuangan/daftar-pemasukan',
            },
            {
                name: 'Daftar Pengeluaran',
                permission: null,
                route: '/laporan/keuangan/daftar-pengeluaran',
            },
            {
                name: 'Laporan Laba Rugi',
                permission: null,
                route: '/laporan/keuangan/laba-rugi',
            },
            {
                name: 'Kategori Akun',
                permission: null,
                route: '/laporan/keuangan/kategori-akun',
            },
        ],
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
        ),
    },
    {
        name: 'Karyawan',
        permission: null,
        route: '/laporan/karyawan',
        childs: [
            // {
            //     name: 'Daftar Karyawan',
            //     permission: null,
            //     route: '/manager/laporan/karyawan/list',
            // },
            {
                name: 'Absensi',
                permission: null,
                route: '/laporan/karyawan/absensi',
            },
            {
                name: 'Laporan Gaji',
                permission: null,
                route: '/laporan/karyawan/gaji',
            },
            {
                name: 'Kasbon',
                permission: null,
                route: '/laporan/karyawan/kasbon',
            },
        ],
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
            </svg>
        ),
    },
];

export default SidebarReportConfig