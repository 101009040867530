import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { _hasPermission } from '../../utils/hasPermission'

export default function SidebarItemWithChild({ data, role, permissions }) {
    const { asPath, query } = useRouter()
    const { id } = query
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (asPath.includes(data.route)) {
            setOpen(true)
        }
    }, [])

    return (
        <>
            <a
                className="sidebar-item flex justify-between items-center cursor-pointer"
                onClick={() => setOpen(!open)}
            >
                <div className="flex items-center">
                    <span className="mr-3">{data.icon}</span>
                    {data.name}
                </div>
                <div>
                    {open ? (
                        <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    ) : (
                        <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                            />
                        </svg>
                    )}
                </div>
            </a>

            <div className={open ? 'space-y-1 mt-1' : 'hidden'}>
                {data?.childs?.map((item, idx) => (
                    <React.Fragment key={idx}>
                        {item?.permission !== null && _hasPermission(item.permission, permissions) &&
                            <Link
                                key={`${item?.name}-a`}
                                href={role === 'MANAGER' ? `/${id}${item.route}` : item.route}
                            >
                                <a
                                    className={
                                        asPath.includes(item.route)
                                            ? 'group rounded-lg w-full flex items-center pl-12 pr-2 py-2 text-sm font-medium text-white bg-mkps-primary-dark hover:text-white'
                                            : 'group rounded-lg w-full flex items-center pl-12 pr-2 py-2 text-sm font-medium text-indigo-100 hover:bg-mkps-primary-light hover:text-white'
                                    }
                                >
                                    {item.name}
                                </a>
                            </Link>
                        }

                        {item?.permission === null &&
                            <Link
                                key={`${item?.name}-b`}
                                href={role === 'MANAGER' ? `/${id}${item.route}` : item.route}
                            >
                                <a
                                    className={
                                        asPath.includes(item.route)
                                            ? 'group rounded-lg w-full flex items-center pl-12 pr-2 py-2 text-sm font-medium text-white bg-mkps-primary-dark hover:text-white'
                                            : 'group rounded-lg w-full flex items-center pl-12 pr-2 py-2 text-sm font-medium text-indigo-100 hover:bg-mkps-primary-light hover:text-white'
                                    }
                                >
                                    {item.name}
                                </a>
                            </Link>
                        }
                    </React.Fragment>
                ))}
            </div>
        </>
    )
}
