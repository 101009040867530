import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { statusOnlineState } from "../store/statusOnlineState";

export default function useOnlineStatus() {
    const [isOnline, setIsOnline] = useRecoilState(statusOnlineState)

    const checkOnlineStatus = async () => {
        console.log("checking online status", navigator.onLine);
        if (navigator.onLine) {
            // setTimeout(() => {
            //     fetch("//google.com", {
            //         mode: "no-cors"
            //     })
            //     .then(() => setIsOnline(true))
            //     .catch(() => setIsOnline(false))
            // }, 5000)
            setIsOnline(true)
        } else {
            setIsOnline(false)
        }
        // try {
        //     console.log("ping google", resp);
        //     // setIsOnline(true)
        //     return
        // } catch (error) {
        //     // setIsOnline(false)
        //     return
        // }
        // if (navigator.onLine) {
        //     setIsOnline(true)
        //     return
        // } else {
        //     setIsOnline(false)
        //     return
        // }
    }

    useEffect(() => {
        console.log("online status", isOnline);
        window.addEventListener("offline", () => {
            setIsOnline(false);
        });
        window.addEventListener("online", () => {
            setIsOnline(true);
        });

        // Add polling incase of slow connection
        const id = setInterval(() => {
            checkOnlineStatus();
        }, 5000);

        return () => {
            window.removeEventListener("offline", () => {
                setIsOnline(false);
            });
            window.removeEventListener("online", () => {
                setIsOnline(true);
            });

            clearInterval(id);
        };
    }, [])

    return {
        isOnline
    }
}