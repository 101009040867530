import Image from 'next/image'
import React from 'react'
import { _hasPermission } from '../../utils/hasPermission'
import SidebarItem from './SidebarItem'
import SidebarItemWithChild from './SidebarItemWithChild'

export default function Sidebar({ data, role, permissions }) {
    return (
        <>
            <div className="hidden md:flex md:flex-shrink-0 sticky top-0">
                <div className="flex flex-col w-56">
                    {/* <!-- Sidebar component, swap this element with another sidebar if you like --> */}
                    <div className="flex flex-col flex-grow pb-4 bg-mkps-primary overflow-y-auto">
                        {/* <div className='bg-mkps-primary py-5 sticky top-0'>
                            <div className="flex items-center justify-center flex-shrink-0 px-4">
                                <Image src="/logo.png" alt="logo" width={50} height={50} />
                                <div className="font-semibold ml-2 text-white">DASHBOARD</div>
                            </div>
                        </div> */}

                        <div className="pb-10 flex-grow flex flex-col">
                            <nav className="flex-1 px-2 bg-mkps-primary space-y-1">
                                {data?.map((item, idx) => (
                                    <React.Fragment key={idx}>
                                        {permissions && item.permission && _hasPermission(item.permission, permissions) &&
                                            <div key={idx}>
                                                {item?.childs?.length === 0 ? (
                                                    <SidebarItem data={item} role={role} />
                                                ) : (
                                                    <SidebarItemWithChild data={item} role={role} permissions={permissions} />
                                                )}
                                            </div>
                                        }
                                        
                                        {permissions && (!item.permission || item.permission === null) &&
                                            <div key={`${idx}-a`}>
                                                {item?.childs?.length === 0 ? (
                                                    <SidebarItem data={item} role={role} />
                                                ) : (
                                                    <SidebarItemWithChild data={item} role={role} permissions={permissions} />
                                                )}
                                            </div>
                                        }
                                    </React.Fragment>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
