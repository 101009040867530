import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRecoilValue } from 'recoil'
import { userState } from '../../store/userState'

export default function SidebarItem({ data, role }) {
    const user = useRecoilValue(userState)
    const { asPath, query } = useRouter()
    const { id } = query

    const routeLink = data.route === '/pengaturan/outlet' && user.store !== null ? `${data.route}/${user.store?.id}` : data.route

    return (
        <Link href={routeLink}>
            <a
                className={
                    asPath.includes(routeLink) ? 'sidebar-active' : 'sidebar-item'
                }
            >
                <span className="mr-3">{data.icon}</span>
                {data.name}
            </a>
        </Link>
    )
}
