import { Transition } from '@headlessui/react'
import { useRef, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import NavbarItem from './NavbarItem'
import useSWR from 'swr'
import fetcher from '../../services/fetcher'
import { useRecoilState } from "recoil";
import { userState } from "../../store/userState";
import { listOutletState, outletState } from '../../store/outletState'

export default function Navbar(
    {
        setOpenSidebar,
        openProfileMenu,
        setOpenProfileMenu,
        data,
        role,
        permissions,
        openListOutlet,
        setOpenListOutlet,
    }
) {
    const router = useRouter()
    const [user, setUser] = useRecoilState(userState)
    const [selectOutlet, setSelectOutlet] = useRecoilState(outletState)
    const [outlets, setOutlets] = useRecoilState(listOutletState)

    const btnRef = useRef()
    useOnClickOutside(btnRef, () => setOpenProfileMenu(false))
    const listRef = useRef()
    useOnClickOutside(listRef, () => setOpenListOutlet(false))

    // const { data: outletData } = useSWR(
    //     router?.query.id && role === 'MANAGER' ? `/dashboard/stores/${router.query.id}` : null,
    //     fetcher
    // )

    const handleSignOut = async (event) => {
        event.preventDefault()
        Cookies.remove('mengkopos_user_token')
        setUser(null)
        window.location.href = '/login'
    }

    useEffect(() => {
        if (user?.store !== null) {
            setSelectOutlet(user?.store ?? selectOutlet)
        }
    }, [user])

    useEffect(() => {
        setSelectOutlet(selectOutlet)
    }, [selectOutlet])

    return (
        <div className='flex-grow'>
            <div className="relative z-10 flex-shrink-0 flex h-14 bg-mkps-primary">
                <button
                    className="px-4 border-gray-100 text-white focus:outline-none md:hidden"
                    onClick={() => setOpenSidebar(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    {/* <!-- Heroicon name: outline/menu-alt-2 --> */}
                    <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h7"
                        />
                    </svg>
                </button>

                <div className="flex-1 px-4 flex justify-between">
                    <div className="flex-1 flex items-center">
                        <div className="text-lg text-white font-semibold md:hidden">
                            {user?.store === null ? (
                                <div className="">
                                    <div className="mt-1 relative" ref={listRef}>
                                        <button
                                            type="button"
                                            aria-haspopup="listbox"
                                            aria-expanded="true"
                                            aria-labelledby="listbox-label"
                                            className="bg-mkps-primary-dark rounded-lg text-white font-semibold relative w-40 shadow-sm pl-3 pr-10 py-1.5 text-left focus:outline-none focus:ring-1 focus:ring-white focus:border-white text-sm cursor-pointer"
                                            onClick={() => setOpenListOutlet(!openListOutlet)}
                                        >
                                            <span className="block truncate">{selectOutlet?.name}</span>
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                {/* yuhu */}
                                                <svg
                                                    className="h-5 w-5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </span>
                                        </button>

                                        <Transition
                                            show={openListOutlet}
                                            enter=""
                                            enterFrom=""
                                            enterTo=""
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="absolute rounded-lg mt-1 w-full bg-white shadow-lg z-50">
                                                <ul
                                                    tabIndex="-1"
                                                    role="listbox"
                                                    aria-labelledby="listbox-label"
                                                    aria-activedescendant="listbox-item-3"
                                                    className="max-h-60 py-3 mb-0 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                                >
                                                    {outlets?.map((list, idx) => (
                                                        <li
                                                            key={idx}
                                                            id="listbox-option-0"
                                                            role="option"
                                                            className={`${list.isActive ? 'text-gray-900 cursor-pointer' : 'text-gray-400 cursor-not-allowed'} select-none relative py-2 pl-3 pr-9 hover:bg-gray-200`}
                                                            onClick={() => {
                                                                if (list.isActive) {
                                                                    setSelectOutlet(list)
                                                                    setOpenListOutlet(false)
                                                                }
                                                            }}
                                                        >
                                                            <span className="font-normal block truncate capitalize">
                                                                {list.name}
                                                            </span>
                                                            {list.name === selectOutlet.name ? (
                                                                <span
                                                                    className="text-mkps-primary absolute inset-y-0 right-0 flex items-center pr-4">
                                                                    {/* <!-- Heroicon name: solid/check --> */}
                                                                    <svg
                                                                        className="h-5 w-5"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20"
                                                                        fill="currentColor"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            ) : null}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            ) : null}

                            {user?.store !== null ? (
                                <div className="flex items-center px-4 py-1">
                                    <img
                                        src="/images/outlet.svg"
                                        alt="icon outlet"
                                        className="w-5 h-5 rounded-lg"
                                    />
                                    <div className="ml-3 text-white font-semibold text-sm capitalize">
                                        {user?.store?.name}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="ml-4 flex items-center md:ml-6">
                        <button
                            className="p-1 text-white hover:text-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mkps-primary-dark">
                            <span className="sr-only">View notifications</span>
                            {/* <!-- Heroicon name: outline/bell --> */}
                            <svg
                                className="h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                                />
                            </svg>
                        </button>

                        {/* <!-- Profile dropdown --> */}
                        <div className="ml-3 relative" ref={btnRef}>
                            <div>
                                <button
                                    className="max-w-xs flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-mkps-primary-dark md:p-1 text-white rounded-lg"
                                    id="user-menu"
                                    aria-haspopup="true"
                                    onClick={() => setOpenProfileMenu(!openProfileMenu)}
                                >
                                    <span className="sr-only">Open user menu</span>
                                    <img
                                        className="h-8 w-8 rounded-lg"
                                        src={user?.image ??
                                            `https://avatars.dicebear.com/api/initials/${user?.name}.svg`
                                            // `https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=GSmyX5uh21&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80`
                                        }
                                        alt="user"
                                    />
                                    <div className="px-2 md:block hidden capitalize">
                                        {user?.name}
                                    </div>
                                    <svg
                                        className="w-4 h-4 md:block hidden"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <Transition
                                show={openProfileMenu}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <div
                                    // ref={btnRef}
                                    className="origin-top-right absolute right-0 mt-2 w-48 shadow-lg rounded-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu"
                                >
                                    <Link href={`/profile`}>
                                        <a
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg"
                                            role="menuitem"
                                        >
                                            Your Profile
                                        </a>
                                    </Link>

                                    <a
                                        href="#"
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        role="menuitem"
                                        onClick={handleSignOut}
                                    >
                                        Sign out
                                    </a>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-mkps-primary px-4 flex items-center justify-between">
                <nav className="hidden md:flex md:space-x-4" aria-label="Global">
                    {data?.map((item, idx) => (
                        <NavbarItem data={item} key={idx} permissions={permissions} role={role} />
                    ))}
                </nav>

                {/* select list outlet */}
                {user?.store === null ? (
                    <div className="hidden md:block">
                        <div className="mt-1 relative" ref={listRef}>
                            <button
                                type="button"
                                aria-haspopup="listbox"
                                aria-expanded="true"
                                aria-labelledby="listbox-label"
                                className="rounded-lg bg-mkps-primary-light text-white font-semibold relative w-40 shadow-sm pl-3 pr-10 py-1.5 text-left focus:outline-none focus:ring-1 focus:ring-white focus:border-none sm:text-sm cursor-pointer"
                                onClick={() => setOpenListOutlet(!openListOutlet)}
                            >
                                <span className="block truncate">{selectOutlet?.name}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    {/* <!-- Heroicon name: solid/selector --> */}
                                    <svg
                                        className="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </span>
                            </button>

                            <Transition
                                show={openListOutlet}
                                enter=""
                                enterFrom=""
                                enterTo=""
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute mt-2 w-full rounded-lg bg-white shadow-lg z-40">
                                    <ul
                                        tabIndex="-1"
                                        role="listbox"
                                        aria-labelledby="listbox-label"
                                        aria-activedescendant="listbox-item-3"
                                        className="max-h-60 mb-0 py-3 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                    >
                                        {outlets?.map((list, idx) => (
                                            <li
                                                key={idx}
                                                id="listbox-option-0"
                                                role="option"
                                                className={`${list.isActive ? 'text-gray-900 cursor-pointer' : 'text-gray-400 cursor-not-allowed'} select-none relative py-2 pl-3 pr-9 hover:bg-gray-200`}
                                                onClick={() => {
                                                    if (list.isActive) {
                                                        setSelectOutlet(list)
                                                        setOpenListOutlet(false)
                                                    }
                                                }}
                                            >
                                                <span className="font-normal block truncate capitalize">
                                                    {list.name}
                                                </span>

                                                {list.name === selectOutlet.name ? (
                                                    <span
                                                        className="text-mkps-primary absolute inset-y-0 right-0 flex items-center pr-4">
                                                        {/* <!-- Heroicon name: solid/check --> */}
                                                        <svg
                                                            className="h-5 w-5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                ) : null}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Transition>
                        </div>
                    </div>
                ) : null}

                {user?.store !== null &&
                    <div className="hidden md:flex items-center px-4 py-1">
                        <img
                            src="/images/outlet.svg"
                            alt="icon outlet"
                            className="w-5 h-5"
                        />
                        <div className="ml-3 text-white font-semibold text-sm capitalize">
                            {user?.store?.name}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
