import Head from 'next/head'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import SidebarOverlay from './SidebarOverlay'
import { useRouter } from 'next/router'
import SidebarSalesConfig from './config/SidebarSalesConfig'
import { useRecoilState } from "recoil";
import { userState } from "../../store/userState";
import { meProfile } from "../../services/user.service";
import { _hasPermission } from '../../utils/hasPermission'
import NavbarConfig from './config/NavbarConfig'
import SidebarReportConfig from './config/SidebarReportConfig'
import SidebarSettingConfig from './config/SidebarSettingConfig'
import SidebarContentConfig from './config/SidebarContentConfig'
import { LoadingPage } from '../../components/pages/LoadingPage'
import Cookies from 'js-cookie'
import useOnlineStatus from '../../hooks/useOnlineStatus'
import { OfflinePage } from '../../components/pages/OfflinePage'
import useSWR from 'swr'
import fetcher from '../../services/fetcher'
import { listOutletState, outletState } from '../../store/outletState'
import Image from 'next/image'
import { sideBarState } from '../../store/sidebarState'
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'

// export async function getServerSideProps(context) {
//     const token = context.req.cookies.mengkopos_user_token ?? null
//     console.log("context", context);
//     console.log("req", context.req);
//     console.log("cookies", context.req.cookies);
//     console.log("tokennnn", token);
//     if (!token) {
//         return {
//             redirect: {
//                 permanent: false,
//                 destination: '/login'
//             }
//         }
//     }
//     return { props: { token: token } }
// }

export default function Dashboard({ token, children = null, withSideBar = true }) {
    const [user, setUser] = useRecoilState(userState)
    const [sidebarOpen, setSideBarOpen] = useRecoilState(sideBarState)
    const [outlets, setOutlets] = useRecoilState(listOutletState)
    const [selectOutlet, setSelectOutlet] = useRecoilState(outletState)

    const { data: allOutlet } = useSWR(
        user?.store === null ? `/dashboard/stores?perPage=9999` : null,
        fetcher
    )

    const { isOnline } = useOnlineStatus()
    const [openSidebar, setOpenSidebar] = useState(false)
    const [openProfileMenu, setOpenProfileMenu] = useState(false)
    const [openListOutlet, setOpenListOutlet] = useState(false)
    const [sidebarConfig, setSidebarConfig] = useState([])
    const [navbarConfig, setNavbarConfig] = useState([])
    const router = useRouter()
    const { asPath, replace } = useRouter()

    const getMeProfile = async () => {
        console.log("fetching data user");
        // fetching data /me and store response to userState
        try {
            const response = await meProfile()
            if (response && response.data) {
                console.log("response data user", response);
                const userData = response?.data?.data
                // console.log(Object.keys(user));
                console.log("data user fetched", userData);
                setUser(userData)
            } else {
                console.log("error logg", response);
                if (response?.statusCode === 401) {
                    Cookies.remove('mengkopos_user_token')
                    router.replace('/login')
                }
            }
        } catch (error) {
            console.log("error logg222", error.response);
            // router.replace('/login')
        }
    }

    useEffect(() => {
        if (allOutlet && user?.store === null) {
            const newMapOutlet = [...allOutlet?.data] ?? []
            newMapOutlet.unshift({
                id: '',
                name: 'Semua Outlet',
                isActive: true
            })
            // console.log("allOutlet", allOutlet.data);
            // console.log("newMapOutlet", newMapOutlet);
            setOutlets(newMapOutlet)
        }

        if (user && user?.store !== null) {
            // const newMapOutlet = [...allOutlet?.data] ?? []
            // newMapOutlet.unshift({
            //     id: '',
            //     name: 'Semua',
            //     isActive: true
            // })
            // // console.log("allOutlet", allOutlet.data);
            // // console.log("newMapOutlet", newMapOutlet);
            // setOutlets(newMapOutlet)
            setSelectOutlet({
                id: user?.store?.id,
                name: user.store.name,
                isActive: user.store?.isActive ?? true
            })
        }
    }, [allOutlet])

    useEffect(async () => {
        const token = Cookies.get('mengkopos_user_token')
        console.log("online", isOnline)
        console.log("token cookie", token)
        if (token && isOnline) {
            console.log("data user", user);
            if (!user || !user?.store) {
                console.log("tidak ada data user");
                await getMeProfile()
            } else { return }
        } else {
            router.replace('/login')
        }
    }, [router])

    useEffect(() => {
        if (user) {
            setNavbarConfig(NavbarConfig)
            if (asPath?.includes('/penjualan') || asPath === '/') {
                setSidebarConfig(SidebarSalesConfig)
            }
            if (asPath?.includes('/laporan')) {
                setSidebarConfig(SidebarReportConfig)
            }
            if (asPath?.includes('/pengaturan') || asPath === '/profile') {
                setSidebarConfig(SidebarSettingConfig)
            }
            if (asPath?.includes('/konten')) {
                setSidebarConfig(SidebarContentConfig)
            }
        }
    }, [user])

    useEffect(() => {
        console.log("status online", isOnline);
    }, [])

    if (!isOnline) return <OfflinePage />
    else if (!user && isOnline) return <LoadingPage />
    return (
        <div>
            <Head>
                <title>Dashboard | Mengkopos | Aplikasi Kasir Online | Membawa Usaha Anda Ke Era Digital</title>
                <meta name="description" content="Dashboard Aplikasi Kasir Online, Hadir Membawa Usaha Anda Ke Era Digital" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            
            {!isOnline && <OfflinePage />}
            <div className="flex h-screen overflow-hidden bg-gray-100">
                <div className="flex flex-col flex-1 w-0 overflow-hidden h-screen">
                    <div className='flex'>
                        <div className='bg-mkps-primary py-3 hidden md:flex w-64 justify-between items-center px-4'>
                            <div className="flex items-center justify-center flex-shrink-0">
                                <Image src="/logo.png" alt="logo" width={50} height={50} />
                                <div className="font-semibold ml-2 text-white">DASHBOARD</div>
                            </div>
                            {sidebarOpen ?
                                <AiOutlineMenuFold
                                    className='text-white h-6 w-6 cursor-pointer'
                                    onClick={() => setSideBarOpen(false)}
                                />
                                :
                                <AiOutlineMenuUnfold
                                    className='text-white h-6 w-6 cursor-pointer'
                                    onClick={() => setSideBarOpen(true)}
                                />
                            }
                        </div>

                        <Navbar
                            setOpenSidebar={setOpenSidebar}
                            openProfileMenu={openProfileMenu}
                            setOpenProfileMenu={setOpenProfileMenu}
                            data={navbarConfig}
                            role={user?.role?.name}
                            permissions={user?.role?.permissions}
                            openListOutlet={openListOutlet}
                            setOpenListOutlet={setOpenListOutlet}
                        />
                    </div>

                    <main
                        className="relative flex overflow-y-auto focus:outline-none flex-grow w-full"
                        tabIndex="0"
                    >
                        {/* <!-- Sidebar for mobile --> */}
                        {withSideBar &&
                            <SidebarOverlay
                                openSidebar={openSidebar}
                                navbarData={navbarConfig}
                                setOpenSidebar={setOpenSidebar}
                                data={sidebarConfig}
                                role={user?.role?.name}
                            />
                        }

                        {/* <!-- Static sidebar for desktop --> */}
                        {withSideBar && sidebarOpen &&
                            <Sidebar data={sidebarConfig} role={user?.role?.name} permissions={user?.role?.permissions} />
                        }
                        <div className="p-4 lg:p-6 flex flex-col flex-grow overflow-auto">
                            {!user ? 'loading...' : children}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}
