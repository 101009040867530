import { VscKey } from "react-icons/vsc";
import { MdOutlinePriceChange } from "react-icons/md";
import { IoBusinessOutline } from "react-icons/io5";

const SidebarSettingConfig = [
    // Owner Pengaturan
    {
        name: 'Outlet',
        permission: 'READ_STORE',
        route: '/pengaturan/outlet',
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                />
            </svg>
        ),
        childs: [],
    },
    {
        name: 'Bisnis',
        permission: 'READ_COMPANY',
        route: '/pengaturan/bisnis',
        icon: (
            <IoBusinessOutline className="w-6 h-6" />
        ),
        childs: [],
    },
    {
        name: 'Plan & Billing',
        permission: null,
        route: '/pengaturan/billing',
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
            </svg>
        ),
        childs: [],
    },
    {
        name: 'Karyawan',
        permission: 'READ_USER',
        route: '/pengaturan/karyawan/list',
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
            </svg>
        ),
        childs: [],
    },
    {
        name: 'Metode Pembayaran',
        permission: null,
        route: '/pengaturan/metode-pembayaran',
        childs: [],
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
            </svg>
        ),
    },
    {
        name: 'Kustom Harga',
        permission: 'READ_CUSTOM_PRICE',
        route: '/pengaturan/kustom-harga/daftar-kustom-harga',
        childs: [],
        icon: (
            <MdOutlinePriceChange className="h-6 w-6" />
        ),
    },
    {
        name: 'Hak Akses',
        permission: 'READ_ROLE',
        route: '/pengaturan/hak-akses',
        childs: [],
        icon: (
            <VscKey className="w-6 h-6 text-white" />
        ),
    },
    {
        name: 'Perangkat Terhubung',
        permission: null,
        route: '/pengaturan/perangkat',
        icon: (
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                />
            </svg>
        ),
        childs: [],
    },
    // {
    //     name: 'Pengaturan Harga',
    //     permission: null,
    //     route: '/manager/pengaturan/harga',
    //     childs: [],
    //     icon: (
    //         <svg
    //             className="w-6 h-6"
    //             fill="none"
    //             stroke="currentColor"
    //             viewBox="0 0 24 24"
    //             xmlns="http://www.w3.org/2000/svg"
    //         >
    //             <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth={2}
    //                 d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
    //             />
    //         </svg>
    //     ),
    // },
    // {
    //     name: 'Pengaturan Kasir',
    //     permission: null,
    //     route: '/manager/pengaturan/kasir',
    //     childs: [],
    //     icon: (
    //         <svg
    //             className="w-6 h-6"
    //             fill="none"
    //             stroke="currentColor"
    //             viewBox="0 0 24 24"
    //             xmlns="http://www.w3.org/2000/svg"
    //         >
    //             <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth={2}
    //                 d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
    //             />
    //         </svg>
    //     ),
    // },
    // {
    //     name: 'Pengaturan Struk',
    //     permission: null,
    //     route: '/manager/pengaturan/struk',
    //     childs: [],
    //     icon: (
    //         <svg
    //             className="w-6 h-6"
    //             fill="none"
    //             stroke="currentColor"
    //             viewBox="0 0 24 24"
    //             xmlns="http://www.w3.org/2000/svg"
    //         >
    //             <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth={2}
    //                 d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
    //             />
    //         </svg>
    //     ),
    // },

    // Super Admin Pengaturan
    // {
    //     name: 'Tim',
    //     permission: null,
    //     route: '/pengaturan/tim',
    //     childs: [],
    //     icon: (
    //         <svg
    //             className="w-6 h-6"
    //             fill="none"
    //             stroke="currentColor"
    //             viewBox="0 0 24 24"
    //             xmlns="http://www.w3.org/2000/svg"
    //         >
    //             <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth={2}
    //                 d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    //             />
    //         </svg>
    //     ),
    // },
    // {
    //     name: 'Rekening Bank',
    //     permission: null,
    //     route: '/pengaturan/rekening-bank',
    //     childs: [],
    //     icon: (
    //         <svg
    //             className="w-6 h-6"
    //             fill="none"
    //             stroke="currentColor"
    //             viewBox="0 0 24 24"
    //             xmlns="http://www.w3.org/2000/svg"
    //         >
    //             <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth={2}
    //                 d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
    //             />
    //         </svg>
    //     ),
    // },
];

export default SidebarSettingConfig