import { axiosInstance } from './http.service'

export const getUserById = async (id) => {
  const response = await axiosInstance.get(`/dashboard/users/${id}`)
  return response.data
}

export const login = async (data) => {
  const response = await axiosInstance.post('/dashboard/auth/login', data)
  return response
}

export const forgotPassword = async (data) => {
  const response = await axiosInstance.post('/dashboard/auth/forgot-password', data)
  return response
}

export const resetPassword = async (data) => {
  const response = await axiosInstance.post('/dashboard/auth/reset-password', data)
  return response
}

export const meProfile = async () => {
  try {
    const response = await axiosInstance.get('/dashboard/users/profile')
    return response
  } catch (error) {
    const response = {statusCode: 401}
    return response
  }
}

// export const sendVerifyEmail = async (data) => {
//   const response = await axiosInstance.post('/dashboard/auth/send-verify-email', data)
//   return response
// }

export const confirmEmailVerification = async (data) => {
  const response = await axiosInstance.post('/dashboard/auth/confirm-email', data)
  return response
}

export const verifyEmail = async (data) => {
  const response = await axiosInstance.post('/dashboard/auth/verify-email', data)
  return response
}

export const loginAdmin = async (data) => {
  const response = await axiosInstance.post('/dashboard/auth/login-admin', data)
  return response
}

export const register = async (data) => {
  const response = await axiosInstance.post('/dashboard/auth/register', data)
  return response
}

export const addEmployee = async (data) => {
  const response = await axiosInstance.post('/dashboard/users', data, {
    headers: {
      "Content-Type": "multipart/form-data;application/json",
    }
  })
  return response
}

export const setNewPassword = async (data, id) => {
  const response = await axiosInstance.post(`/dashboard/users/${id}/change-password`, data)
  return response
}

export const setNewPasswordMobile = async (data, id) => {
  const response = await axiosInstance.post(`/dashboard/users/${id}/change-mobile-password`, data)
  return response
}

export const updateDataUser = async (data, id) => {
  const response = await axiosInstance.patch(`/dashboard/users/${id}`, data)
  return response
}

// export const deleteEmployee = async (id) => {
//   const response = await axiosInstance.delete(`/dashboard/users/${id}`)
//   return response
// }
